<template>
  <router-view/>
  <BaseFooter/>
</template>

<script>
import BaseFooter from './components/Base/BaseFooter.vue'

export default {
  name: 'App',
  components: {
    BaseFooter
  }
}
</script>
