<template>
  <section id="carousel">
    <div class="relative flex flex-wrap mx-auto">
      <div
        class="w-full h-screen bg-zoku-pattern bg-no-repeat bg-cover bg-center"
      >
        <BaseHeader />
      </div>
      <div class="absolute inset-x-0 sm:-bottom-80 -bottom-52 w-full">
        <div
          class="
            bg-white
            w-[90%]
            drop-shadow-xl
            h-full
            justify-center
            text-black text-center
            mx-auto
          "
        >
          <div
            class="flex flex-row justify-between sm:px-28 px-12 sm:py-8 py-4"
          >
            <img
              class="h-auto w-2/5 sm:w-1/3"
              src="../assets/Elements/ELEMENTS-11.png"
              alt="Zoku"
            />
            <div
              class="
                flex flex-col
                sm:text-[40px]
                text-[10px]
                font-light
                justify-center
                py-3
                sm:py-0
              "
            >
              <p>EST. 2018</p>
              <p>SARAWAK</p>
            </div>
          </div>
          <div class="border-b border-black w-5/6 mx-auto"></div>
          <p
            class="text-[20px] sm:text-[40px] font-light w-5/6 mx-auto sm:mx-0"
          >
            A HUB FOR ALL YOUR COMMUNITIES’ NEEDS
          </p>
          <div class="border-b border-black w-5/6 mx-auto"></div>
          <p
            class="
              text-[15px]
              sm:text-[35px]
              font-black
              w-4/6
              sm:py-2
              py-2
              mx-auto
            "
          >
            "Breaking Limit's logo exalts simplicity and trust, values which are
            highly appreciated by our clients"
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-white sm:h-96 h-64"></section>
  <section id="sectionZoku" class="bg-white">
    <img
      class="h-auto w-[90%] mx-auto mb-4"
      src="../assets/Photos/22020528_BL_KG_WEBDRAFT_0011024_1.jpeg"
      alt="ZokuPDF"
    />
  </section>
  <section id="sectionContactUs">
    <div class="flex flex-wrap mx-auto">
      <div class="w-full lg:w-1/2">
        <div
          class="w-full h-full bg-no-repeat bg-center bg-cover relative"
          :style="{
            backgroundImage: `url(${require('@/assets/Photos/contactUs.jpeg')})`,
          }"
        >
          <div
            class="
              w-full
              h-full
              flex flex-col
              justify-center
              text-white
              sm:px-6 sm:py-16
              py-6
              px-2
            "
          >
            <p
              class="
                sm:w-4/6
                w-full
                text-[50px]
                sm:text-[60px]
                font-bold
                sm:text-left
                text-center
                sm:pl-6
                pb-4
                pt-24
              "
            >
              Let's discuss your project
            </p>
            <p
              class="
                text-[15px]
                sm:text-[25px]
                text-justify
                w-full
                sm:w-[75%] sm:text-left
                text-center
                sm:pl-6
                px-4
              "
            >
              With us, the limit does not exist. Connect with us today to reach
              your full potential.
            </p>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full p-2 lg:w-1/2">
        <h1
          class="
            sm:text-[50px]
            text-[30px]
            font-bold
            text-blue-black
            sm:pt-16
            pt-3
            pb-2
            px-4
            sm:px-10
            text-center
            sm:text-left
          "
        >
          Get In Touch
        </h1>
        <form
          @submit.prevent="createMessage"
          class="px-4 sm:px-10 text-center sm:text-left w-[90%]"
        >
          <input
            v-model="form.name"
            type="text"
            placeholder="Your Name"
            required
            class="py-3 block w-full text-[25px] border-b border-blue-black1"
          />
          <input
            v-model="form.email"
            type="email"
            placeholder="Your Email"
            required
            class="py-3 block w-full text-[25px] border-b border-blue-black1"
          />
          <input
            v-model="form.message"
            type="text"
            placeholder="Write your message here"
            required
            class="py-3 block w-full text-[25px] border-b border-blue-black1"
          />
          <button class="pt-8 sm:float-right">
            <div
              class="block w-44 h-12 bg-no-repeat bg-contain relative"
              :style="{
                backgroundImage: `url(${require('@/assets/Elements/ELEMENTS-12.png')})`,
              }"
            >
              <div
                class="
                  w-full
                  h-full
                  flex flex-col
                  justify-center
                  text-white
                  mx-auto
                  py-2
                "
              >
                <p class="w-full text-[15px] font-bold text-center">SEND</p>
              </div>
            </div>
          </button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import BaseHeader from "../components/Base/BaseHeader";
import firebaseApp from "../firebaseInit";
import { getFirestore, collection, addDoc } from "firebase/firestore";

const db = getFirestore(firebaseApp, {});

export default {
  name: "ZokuPage",
  components: {
    BaseHeader,
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    async createMessage() {
      if (this.form.name != "") {
        const docRef = await addDoc(collection(db, "contacts"), this.form);
        console.log("Document written with ID: ", docRef.id);
        alert("Message added!");
        this.form.name = "";
        this.form.email = "";
        this.form.message = "";
      }
    },
  },
};
</script>