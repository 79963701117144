// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC5fEWdNTDZIgFWfZGG6b6R8fnLYXhTYf4",
  authDomain: "breaking-limit-351907.firebaseapp.com",
  projectId: "breaking-limit-351907",
  storageBucket: "breaking-limit-351907.appspot.com",
  messagingSenderId: "667397039432",
  appId: "1:667397039432:web:48b5e661cb6a5c85d8bf7d",
  measurementId: "G-8957W2JX6T",
};
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);

// Initialize Firebase
export default app;
