<template>
  <footer class="block text-white">
    <div class="px-4 bg-dark-gray py-8">
      <div class="container mx-auto">
        <div class="flex flex-wrap items-center md:justify-between justify-center">
          <div class="w-full md:w-4/12 px-4">
            <div class="text-[32px] font-bold py-1">
              Breaking Limit Sdn. Bhd.
            </div>
            <div class="text-[16px] font-light">
              The limit does not exist
            </div>
          </div>
          <div class="w-full md:w-8/12 px-4 py-4">
            <ul class="flex flex-wrap list-none md:justify-end  justify-center">
              <li>
                <a
                    href="#sectionWhatWeDo"
                    class="text-blueGray-600 hover:text-blueGray-800 text-sm font-semibold block py-1 px-3"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                    href="#sectionContactUs"
                    class="text-blueGray-600 hover:text-blueGray-800 text-sm font-semibold block py-1 px-3"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="px-4 bg-black">
      <div class="container mx-auto">
        <div class="text-[13px] font-bold py-1">
          © {{ new Date().getFullYear() }} Breaking Limit Sdn. Bhd. All Rights Reserved.
        </div>
      </div>
    </div>
  </footer>

</template>

<script>
export default {
  name: "BaseFooter"
}
</script>

<style scoped>

</style>