<template>
  <section id="carousel">
    <div class="relative flex flex-wrap mx-auto">
      <div
        class="w-full h-screen bg-hero-pattern bg-no-repeat bg-cover bg-center"
      >
        <BaseHeader />
        <div
          class="
            w-full
            h-full
            flex flex-col
            justify-center
            text-white text-center
          "
        >
          <p class="w-5/6 mx-auto text-[60px] sm:text-[80px] font-bold">
            We are here for you
          </p>
          <p class="w-3/6 mx-auto text-[15px] sm:text-[25px]">
            We are an outsourcing company who will be able to provide you
            solutions that will help to make your company stand out among the
            rest
          </p>
        </div>
      </div>
      <div class="absolute sm:-bottom-80 -bottom-72 w-full">
        <div
          class="
            bg-white
            w-[90%]
            drop-shadow-xl
            h-full
            justify-center
            text-black text-center
            mx-auto
          "
        >
          <div class="flex flex-row justify-center">
            <img
              class="h-auto w-[70%] sm:w-4/5"
              src="../assets/Logo/BL_INVERT.png"
              alt="BL"
            />
            <div
              class="
                flex flex-col
                sm:text-[40px]
                text-[10px]
                font-light
                justify-center
                py-3
                sm:py-0
              "
            >
              <p>EST. 2018</p>
              <p>SARAWAK</p>
            </div>
          </div>
          <div class="border-b border-black w-5/6 mx-auto"></div>
          <p
            class="text-[25px] sm:text-[40px] font-light w-5/6 mx-auto sm:mx-0"
          >
            (OCS) OUTSOURCING & CONSULTING SERVICES
          </p>
          <div class="border-b border-black w-5/6 mx-auto"></div>
          <p class="text-[15px] sm:text-[35px] font-black w-4/6 py-12 mx-auto">
            "Breaking Limit's logo exalts simplicity and trust, values which are
            highly appreciated by our clients"
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-white sm:h-96 h-80"></section>
  <section id="sectionWhatWeDo" class="bg-white">
    <div class="flex flex-wrap mx-auto py-4 lg:py-0">
      <div class="w-full lg:w-2/5">
        <div
          class="w-full h-full bg-no-repeat bg-center bg-cover relative"
          :style="{
            backgroundImage: `url(${require('@/assets/Photos/WhatDoWeDo-Image.jpeg')})`,
          }"
        >
          <div
            class="
              w-full
              h-full
              flex flex-col
              justify-center
              text-white
              sm:px-6 sm:py-16
              py-6
              px-2
            "
          >
            <p
              class="
                sm:w-4/6
                w-full
                text-[45px]
                sm:text-[60px]
                font-bold
                sm:text-left
                text-center
                sm:pl-6
                pb-4
                pt-24
              "
            >
              What do we do?
            </p>
            <p
              class="
                text-[15px]
                sm:text-[25px]
                text-justify
                w-full
                sm:w-[75%] sm:text-left
                text-center
                sm:pl-6
                px-4
              "
            >
              As a group of adventurous innovators & avid doers, we aim to
              deliver the best and highest quality of services
            </p>
          </div>
        </div>
      </div>
      <div class="w-full p-2 lg:w-3/5">
        <div
          class="
            lg:mt-6
            mt-0
            grid
            gap-y-10 gap-x-6
            sm:grid-cols-1
            lg:grid-cols-2
            xl:gap-x-8
            px-2
            lg:px-8
          "
        >
          <div v-for="s in stuff" :key="s.id">
            <div class="mt-4 flex justify-between">
              <div>
                <h3 class="text-sm text-gray-700 flex flex-row">
                  <img class="h-8 w-auto" :src="s.icon" :alt="s.name" />
                  <a
                    class="
                      my-auto
                      pl-2
                      sm:text-[24px]
                      text-[14px]
                      font-bold
                      lg:leading-8
                      leading:0
                    "
                    :href="s.href"
                  >
                    {{ s.name }}
                  </a>
                </h3>
                <p class="pl-10 sm:text-[20px] text-[12px] py-0 lg:py-2">
                  {{ s.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="sectionFeaturedProject" class="bg-white">
    <div
      class="max-w-2xl mx-auto py-16 px-4 sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8"
    >
      <p class="text-[45px] sm:text-[60px] font-bold">Featured Project</p>
      <p class="text-[15px] sm:text-[25px] sm:w-[55%]">
        Zoku is a Sino-Japanese term meaning tribe, clan, or family
      </p>
      <div class="flex flex-row justify-end">
        <img
          class="h-auto sm:w-[20%] w-[30%] mr-20"
          src="../assets/Elements/ELEMENTS-11.png"
          alt="Zoku"
        />
      </div>
      <div class="flex flex-row justify-end relative">
        <img
          src="../assets/Photos/ZokuVideoThumbnail.jpeg"
          alt="ZokuVideoThumbnail"
          class="sm:w-[50%] w-[80%] h-auto absolute sm:top-0 top-6 -left-12"
        />
        <div class="bg-dark-red w-[60%] h-80 p-4 mt-48">
          <p
            class="
              text-[15px]
              sm:text-[20px] sm:pt-12
              pt-6
              sm:pl-20
              text-whitish
              sm:pb-0
              pb-4
            "
          >
            Working collaboratively to promote transparency and knowledge
            sharing among a group of people affiliated by geographic proximity,
            special interest or similar goals to address obstacles affecting
            their well-being.
          </p>
          <a href="/zoku" class="sm:float-right">
            <div
              class="block w-44 h-12 bg-no-repeat bg-contain relative"
              :style="{
                backgroundImage: `url(${require('@/assets/Elements/ELEMENTS-12.png')})`,
              }"
            >
              <div
                class="
                  w-full
                  h-full
                  flex flex-col
                  justify-center
                  text-white
                  mx-auto
                  py-2
                "
              >
                <p class="w-full text-[15px] font-bold text-center">
                  LEARN MORE
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
      <!--        <div class="container ml-40 w-1/2 p-4 relative">-->
      <!--          <img src="../assets/Photos/ZokuVideoThumbnail.jpeg" alt="ZokuVideoThumbnail"-->
      <!--               class="w-full h-auto absolute top-0 -left-48 ">-->

      <!--        </div>-->
    </div>
  </section>

  <!--  <section id="sectionWhoAreWe">-->
  <!--    <div class="bg-dark-red">-->
  <!--      <div class="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">-->
  <!--        <h2 class="text-2xl font-extrabold tracking-tight text-gray-900">Customers also purchased</h2>-->

  <!--        <div class="w-1/2 m-auto pt-10">-->
  <!--          <AccordionComponent title="consectetur adipiscing elit">-->
  <!--            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et-->
  <!--            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex-->
  <!--            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat-->
  <!--            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit-->
  <!--            anim id est laborum-->
  <!--          </AccordionComponent>-->
  <!--          <AccordionComponent title="consectetur adipiscing elit">-->
  <!--            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et-->
  <!--            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex-->
  <!--            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat-->
  <!--            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit-->
  <!--            anim id est laborum-->
  <!--          </AccordionComponent>-->
  <!--        </div>-->

  <!--        <div class="mt-6 grid grid-cols-4 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">-->
  <!--          <div v-for="people in peoples" :key="people.id">-->
  <!--            <div-->
  <!--                class="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">-->
  <!--              &lt;!&ndash;              <img :src="people.imageSrc" :alt="people.imageAlt" class="w-full h-full object-center object-cover lg:w-full lg:h-full" />&ndash;&gt;-->
  <!--            </div>-->
  <!--            <div class="mt-4 flex justify-between">-->
  <!--              <div>-->
  <!--                <h3 class="text-sm text-gray-700">-->
  <!--                  <a :href="people.href">-->
  <!--                    <span aria-hidden="true" class="absolute inset-0"/>-->
  <!--                    {{ people.name }}-->
  <!--                  </a>-->
  <!--                </h3>-->
  <!--                <p class="mt-1 text-sm text-gray-500">{{ people.description }}</p>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </section>-->
  <section id="sectionContactUs">
    <div class="flex flex-wrap mx-auto">
      <div class="w-full lg:w-1/2">
        <div
          class="w-full h-full bg-no-repeat bg-center bg-cover relative"
          :style="{
            backgroundImage: `url(${require('@/assets/Photos/contactUs.jpeg')})`,
          }"
        >
          <div
            class="
              w-full
              h-full
              flex flex-col
              justify-center
              text-white
              sm:px-6 sm:py-16
              py-6
              px-2
            "
          >
            <p
              class="
                sm:w-4/6
                w-full
                text-[50px]
                sm:text-[60px]
                font-bold
                sm:text-left
                text-center
                sm:pl-6
                pb-4
                pt-24
              "
            >
              Let's discuss your project
            </p>
            <p
              class="
                text-[15px]
                sm:text-[25px]
                text-justify
                w-full
                sm:w-[75%] sm:text-left
                text-center
                sm:pl-6
                px-4
              "
            >
              With us, the limit does not exist. Connect with us today to reach
              your full potential.
            </p>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full p-2 lg:w-1/2">
        <h1
          class="
            sm:text-[50px]
            text-[30px]
            font-bold
            text-blue-black
            sm:pt-16
            pt-3
            pb-2
            px-4
            sm:px-10
            text-center
            sm:text-left
          "
        >
          Get In Touch
        </h1>
        <form
          @submit.prevent="createMessage"
          class="px-4 sm:px-10 text-center sm:text-left w-[90%]"
        >
          <input
            v-model="form.name"
            type="text"
            placeholder="Your Name"
            required
            class="py-3 block w-full text-[25px] border-b border-blue-black1"
          />
          <input
            v-model="form.email"
            type="email"
            placeholder="Your Email"
            required
            class="py-3 block w-full text-[25px] border-b border-blue-black1"
          />
          <input
            v-model="form.message"
            type="text"
            placeholder="Write your message here"
            required
            class="py-3 block w-full text-[25px] border-b border-blue-black1"
          />
          <button class="pt-8 sm:float-right">
            <div
              class="block w-44 h-12 bg-no-repeat bg-contain relative"
              :style="{
                backgroundImage: `url(${require('@/assets/Elements/ELEMENTS-12.png')})`,
              }"
            >
              <div
                class="
                  w-full
                  h-full
                  flex flex-col
                  justify-center
                  text-white
                  mx-auto
                  py-2
                "
              >
                <p class="w-full text-[15px] font-bold text-center">SEND</p>
              </div>
            </div>
          </button>
        </form>
      </div>
    </div>
  </section>
</template>
<script setup>
// const peoples = [
//   {
//     id: 1,
//     prefix: 'DW',
//     name: 'Dennis Wee',
//     href: '#',
//     role: 'Co-Founder & Chief Executive Officer',
//     description: 'Dennis involves in business strategy, operations, leadership and business transformation. Prior to founding BREAKING LIMIT, he has experience working in business development in telecommunication and ICT corporations.'
//   },
//   {
//     id: 2,
//     prefix: 'AJ',
//     name: 'Awg Jaffaruddin',
//     href: '#',
//     role: 'Co-Founder & Operation Director',
//     description: 'Awg Jaffaruddin has an extensive experience in telecommunication and ICT industry. He has prior experience in designing and managing domestic and regional telecommunication infrastructure.'
//   },
//   {
//     id: 3,
//     prefix: 'KG',
//     name: 'Kelvin Gerad',
//     href: '#',
//     role: 'Co-Founder & Business Development Director',
//     description: 'Kelvin has worked in telecommunication corporation with a strong focus on a new product and sales and marketing area, which helps in building new businesses. He is also involved in corporate development like public engagement and corporate training.'
//   },
//   {
//     id: 4,
//     prefix: 'JZ',
//     name: 'Jonathan Zheng',
//     href: '#',
//     role: 'Technical Solutions Director',
//     description: 'Jonathan helps business and ICT company use existing and emerging technologies and to innovate customer proposition and transform business operations. He also have a vast experience in project management covering domestic and international corporations.'
//   },
// ]
const stuff = [
  {
    id: 1,
    name: "Business Consultancy",
    icon: require("../assets/Icons/BL-Icons-01.png"),
    href: "#",
    description:
      "This is a customised consulting service for various businesses and company situations. We advise on both organisation structural changes as well as resource centric solutions to improve performance and efficiency of businesses.",
  },
  {
    id: 2,
    name: "Developing Community Solution",
    icon: require("../assets/Icons/BL-Icons-02.png"),
    href: "#",
    description:
      "We are developing ZOKU, a powerful yet flexible solutions to empower growth of organisation and community.",
  },
  {
    id: 3,
    name: "Training & Certification",
    icon: require("../assets/Icons/BL-Icons-03.png"),
    href: "#",
    description:
      "Our training and certification help individuals learn how to build the most profound employee and people networks.",
  },
  {
    id: 4,
    name: "Outsourcing",
    icon: require("../assets/Icons/BL-Icons-04.png"),
    href: "#",
    description: "We help businesses to reduce cost and improve efficiency.",
  },
];
</script>
<script>
import BaseHeader from "../components/Base/BaseHeader";
// import AccordionComponent from '../components/Accordion.vue'
import firebaseApp from "../firebaseInit";
import { getFirestore, collection, addDoc } from "firebase/firestore";

const db = getFirestore(firebaseApp, {});
export default {
  name: "HomePage",
  components: {
    BaseHeader,
    // AccordionComponent
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    async createMessage() {
      if (this.form.name != "") {
        const docRef = await addDoc(collection(db, "contacts"), this.form);
        console.log("Document written with ID: ", docRef.id);
        alert("Message added!");
        this.form.name = "";
        this.form.email = "";
        this.form.message = "";
      }
    },
  },
};
</script>
